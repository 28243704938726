import React from 'react';
import Home from './Home'
import About from './About'
import FormsContinueParent from './FormsContinueParent'
import Products from './Products'
import Contact from './Contact'
import Thankyou from './Thankyou';
import {Switch, Route} from "react-router-dom"
import Services from './Services';

const Body = () => {

    return (
        <div>

            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route  path="/about" component={About}/>

                <Route  path="/services">
                    <Services />
                </Route>
                <Route  path="/forms">
                    <FormsContinueParent />
                </Route>
                <Route  path="/products">
                    <Products />
                </Route>
                <Route  path="/contact">
                    <Contact />
                </Route>
                <Route path="/thankyou">
                    <Thankyou />
                </Route>
            </Switch>
            
        </div>
    );
}

export default Body;
