import React from 'react';

const Footer = () => {
    return (
        
            <footer className="footer bg-primary container-fluid">
                <br />
                <hr className="white" />
                <div className="row">
                <div className="col-8">
                    Copyright © {new Date().getFullYear()}, Precious Embers. All Rights Reserved.
            </div>
            <ul className="social-network social-circle col-3 ml-auto">
                <li><a href="https://www.facebook.com/michaelstockover" className="icoFacebook" target="_blank" rel="noreferrer" title="Facebook"><i className="fa fa-facebook"></i></a></li>
            </ul>
            </div>
            <br/>       
        </footer>
        
        
    );
}

export default Footer;
