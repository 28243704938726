import React from 'react';
import {NavLink, Link} from "react-router-dom"
import logo from '../content/images/navbar/PRECIOUS EMBERS OCT2020 - ELEGANT VERSION.png'


const NavBar = () => {
    return (
      <div>


        
  <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light top">
        <Link className="navbar-brand" to="/">
          <img className= "logo" src={logo} width="100%" alt=""/>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Home </ NavLink> 
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/About"> About </ NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/Services">Services</ NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/Forms">Forms</ NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/Products">Products</ NavLink>
            </li>  
            <li className="nav-item">
              <NavLink className="nav-link" to="/Contact">Contact</ NavLink>
            </li>  
          </ul>
        </div>  
  </nav>
  </div>
        
    );
}

export default NavBar;


// <Navbar bg="light" expand="lg">
// <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
// <Navbar.Toggle aria-controls="basic-navbar-nav" />
// <Navbar.Collapse id="basic-navbar-nav">
//   <Nav className="mr-auto">
//     <Nav.Link href="#home">Home</Nav.Link>
//     <Nav.Link href="#link">Link</Nav.Link>
//     <NavDropdown title="Dropdown" id="basic-nav-dropdown">
//       <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//       <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
//       <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//       <NavDropdown.Divider />
//       <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
//     </NavDropdown>
//   </Nav>
//   <Form inline>
//     <FormControl type="text" placeholder="Search" className="mr-sm-2" />
//     <Button variant="outline-success">Search</Button>
//   </Form>
// </Navbar.Collapse>
// </Navbar>