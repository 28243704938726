import * as yup from "yup"

const countyList = [
    'N/A (State besides IL)',
    'Cook ',
'DuPage ',
'Lake ',
'Will ',
'Kane ',
'McHenry ',
'Winnebago ',
'Madison ',
'St. Clair ',
'Champaign ',
'Sangamon ',
'Peoria ',
'McLean ',
'Rock Island ',
'Tazewell ',
'Kendall ',
'Kankakee ',
'LaSalle ',
'Macon ',
'DeKalb ',
'Vermilion ',
'Williamson ',
'Adams ',
'Jackson ',
'Whiteside ',
'Boone ',
'Coles ',
'Ogle ',
'Grundy ',
'Knox ',
'Henry ',
'Macoupin ',
'Stephenson ',
'Franklin ',
'Woodford ',
'Jefferson ',
'Marion ',
'Clinton ',
'Livingston ',
'Fulton ',
'Lee ',
'Morgan ',
'Monroe ',
'Effingham ',
'Bureau ',
'Christian ',
'Randolph ',
'McDonough ',
'Logan ',
'Montgomery ',
'Iroquois ',
'Saline ',
'Jersey ',
'Shelby ',
'Jo Daviess ',
'Fayette ',
'Perry ',
'Douglas ',
'Crawford ',
'Hancock ',
'Edgar ',
'Warren ',
'Union ',
'Bond ',
'Wayne ',
'Piatt ',
'Lawrence ',
'De Witt ',
'Richland ',
'Clark ',
'Pike ',
'Mercer ',
'Moultrie ',
'Carroll ',
'Massac ',
'Washington ',
'White ',
'Mason ',
'Clay ',
'Ford ',
'Greene ',
'Johnson ',
'Cass ',
'Menard ',
'Marshall ',
'Wabash ',
'Cumberland ',
'Jasper ',
'Hamilton ',
'Schuyler ',
'Henderson ',
'Brown ',
'Edwards ',
'Alexander ',
'Putnam ',
'Pulaski ',
'Stark ',
'Gallatin ',
'Scott ',
'Calhoun ',
'Pope ',
'Hardin ',
]

export default yup.object().shape({
    decName: yup
    .string().max(70)
    .required(),
    maidenName: yup
    .string().required(),
    sex: yup
    .string()
    .required().oneOf(["Male","Female","Non-binary"]),
    dateOfBirth: yup
    .date()
    .required(),
    placeOfBirth: yup
    .string()
    .required(),
    dateOfDeath: yup
    .date()
    .required(),
    timeOfDeath: yup
    .string()
    .required(),
    placeOfDeathCity: yup
    .string()
    .required(),
    placeOfDeathCounty: yup
    .string()
    .required().oneOf(countyList)
})