import React, { useState } from 'react'

import * as yup from "yup"
import famDeathCertSchema from "../../Validations/famDeathCertSchema"
import NaCheckbox from '../NaCheckbox'

let stateList = [ "AK",
                      "AL",
                      "AR",
                      "AS",
                      "AZ",
                      "CA",
                      "CO",
                      "CT",
                      "DC",
                      "DE",
                      "FL",
                      "GA",
                      "GU",
                      "HI",
                      "IA",
                      "ID",
                      "IL",
                      "IN",
                      "KS",
                      "KY",
                      "LA",
                      "MA",
                      "MD",
                      "ME",
                      "MI",
                      "MN",
                      "MO",
                      "MS",
                      "MT",
                      "NC",
                      "ND",
                      "NE",
                      "NH",
                      "NJ",
                      "NM",
                      "NV",
                      "NY",
                      "OH",
                      "OK",
                      "OR",
                      "PA",
                      "PR",
                      "RI",
                      "SC",
                      "SD",
                      "TN",
                      "TX",
                      "UT",
                      "VA",
                      "VI",
                      "VT",
                      "WA",
                      "WI",
                      "WV",
                      "WY"]

const  initialDeathCertErrors = {
                                decName:"", maidenName:"",sex:"",dateOfBirth:"",dateOfDeath:"",timeOfDeath:"",placeOfDeathCity:"",placeOfDeathCounty:"", decHomeAddressLine1:"", decHomeAddressLine2:"", decHomeAddressCity:"", decHomeAddressState:"", decHomeAddressZip:"", usualOccupation:"", industry:"", education:"", fatherFirstName:"", fatherLastName:"", motherFirstName:"", motherMaidenName:"", physicianName:"", physicianAddress:"", physicianCity:"", physicianPhone:"", physicianFax: "", ssn:"", armedForces:"", maritalStatus:"", spouse:"", race:"", hisp:"", informantFirstName:"", informantLastName:"", informantRelationship:"", informantEmail:"", informantPhone:"", informantCell:"", informantAddressLine1:"", informantAddressLine2:"", informantAddressCity:"", informantAddressState:"", informantAddressZip:"",
                                }

const DeathCertForm = ({decedentObj, setDecedentObj}) => {

    const [deathCertErrors, setDeathCertErrors] = useState(initialDeathCertErrors);
    


    const handleChange = (evt) => {
        const {name, value} = evt.target

        yup.reach(famDeathCertSchema, name)
        .validate(value)
        .then(() => {
            setDeathCertErrors({
                ...deathCertErrors,
                [name]:''
            });
        })
        .catch(err => {
            setDeathCertErrors({
                ...deathCertErrors,
                [name]: err.errors[0]
            });
        });



        setDecedentObj({...decedentObj,
                        [name]: value})
        //console.log(decedentObj)                
    }



    //console.log(deathCertErrors)

    // useEffect(() => {
    //     let interval = setInterval(() => {
    //         if (decNameField.current) {
    //           setDecedentObj({...decedentObj,
    //                             decName: decNameField.current.value})
    //           clearInterval(interval)
    //                         }
    //           //do the same for all autofilled fields
              
    //       }, 100)
    //     });

    // disabled={decedentObj.xxxxxx === "N/A"}/> <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="xxxxxx"/>


    
    return (
        <div className='form-container'>
            <form >
            <div className="form-group">
                     <div className="row form-row">
                        <label htmlFor="fatherFirstName">Father's First Name*: </label>
                        <input type="text" name="fatherFirstName" value={decedentObj.fatherFirstName} onChange={handleChange} disabled={decedentObj.fatherFirstName === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="fatherFirstName"/>
                        <small className="error-display">{deathCertErrors.fatherFirstName}</small>
                     </div>
                     <div className="row form-row">
                        <label htmlFor="fatherLastName">Father's Last Name*: </label>
                        <input type="text" name="fatherLastName" value={decedentObj.fatherLastName} onChange={handleChange} disabled={decedentObj.fatherLastName === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="fatherLastName"/>
                        <small className="error-display">{deathCertErrors.fatherLastName}</small>
                                     </div>
                     <div className="row form-row">
                        <label htmlFor="motherFirstName">Mother's First Name*: </label>
                        <input type="text" name="motherFirstName" value={decedentObj.motherFirstName} onChange={handleChange} disabled={decedentObj.motherFirstName === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="motherFirstName"/>
                                     
                                     <small className="error-display">{deathCertErrors.motherFirstName}</small>
                                     </div>
                     <div className="row form-row">
                        <label htmlFor="motherMaidenName">Mother's Maiden Name*: </label>
                        <input type="text" name="motherMaidenName" value={decedentObj.motherMaidenName} onChange={handleChange} disabled={decedentObj.motherMaidenName === "N/A"}/> <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="motherMaidenName"/>
                        <small className="error-display">{deathCertErrors.motherMaidenName}</small>
                    </div>
                 </div>
                <div className="form-group">
                    <div className="form-row">
                        <b>Legal Next of Kin</b>
                    </div>
                     <div className="row form-row">
                        <label>First Name*: </label>
                            <input type="text" name="informantFirstName" value={decedentObj.informantFirstName} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.informantFirstName}</small>
                        </div>
                     <div className="row form-row">
                        <label>Last Name*: </label>
                            <input type="text" name="informantLastName" value={decedentObj.informantLastName} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.informantLastName}</small>
                        </div>
                     <div className="row form-row">
                        <label htmlFor="relationship">Relationship*: </label>
                        {/* <input type="text" name="informantRelationship" value={decedentObj.informantRelationship} onChange={handleChange}/> */}
                        <select name="informantRelationship"  value={decedentObj.informantRelationship} onChange={handleChange}>
                                                    <option value={decedentObj.informantRelationship}>{decedentObj.informantRelationship}</option>
                                                    <option value="Mother">Mother</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Spouse">Spouse</option>
                                                    <option value="Mother-in-law">Mother-in-law</option>
                                                    <option value="Father-in-law">Father-in-law</option>
                                                    <option value="Daughter">Daughter</option>
                                                    <option value="Son">Son</option>
                                                    <option value="Daughter-in-law">Daughter-in-law</option>
                                                    <option value="Son-in-law">Son-in-law</option>
                                                    <option value="Sister">Sister</option>
                                                    <option value="Brother">Brother</option>
                                                    <option value="Sister-in-law">Sister-in-law</option>
                                                    <option value="Brother-in-law">Brother-in-law</option>
                                                    <option value="Grandchild">Grandchild</option>
                                                    <option value="Niece">Niece</option>
                                                    <option value="Nephew">Nephew</option>
                                                    <option value="Great Grandchild">Great Grandchild</option>
                                                    <option value="Significant Other">Significant Other</option>
                                                </select>
                        <small className="error-display">{deathCertErrors.informantRelationship}</small>
                    </div>
                     <div className="row form-row">
                        <label htmlFor="informantEmail">Email*: </label>
                        <input type="text" name="informantEmail" value={decedentObj.informantEmail} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.informantEmail}</small>
                    </div>
                     <div className="row form-row">
                        <label htmlFor="informantPhone">Phone Number*: </label>
                        <input type="tel" name="informantPhone" value={decedentObj.informantPhone} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.informantPhone}</small>
                    </div>
                </div>
                    
                     <div className="form-group">
                         <div className="form-row">
                             <b>Legal Next of Kin's Address: </b>
                         </div>
                         <div className="row form-row">
                            <label>Street Address Line 1*: </label>
                            <input type="text" name="informantAddressLine1" value={decedentObj.informantAddressLine1} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.informantAddressLine1}</small>
                        </div>
                         <div className="row form-row">
                            <label>Street Address Line 2: </label>
                            <input type="text" name="informantAddressLine2" value={decedentObj.informantAddressLine2} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.informantAddressLine2}</small>
                        </div>
                         <div className="row form-row">
                                <label>City*: </label>
                                <input type="text" name="informantAddressCity" value={decedentObj.informantAddressCity} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.informantAddressCity}</small>
                    
                         </div>

                         <div className="row form-row">
                            <label >State*: </label>
                            <select name="informantAddressState" value={decedentObj.informantAddressState} onChange={handleChange}>
                                <option value="">-Select State-</option>
                                {stateList.map(state => {
                                    return <option value={state}>{state}</option>;
                                })}
                            </select>
                            <small className="error-display">{deathCertErrors.informantAddressState}</small>
                       
                        </div>
                    
                         <div className="row form-row">
                            <label htmlFor="informantAddressZip">Postal/Zip Code*: </label>
                            <input type="text" name="informantAddressZip" value={decedentObj.informantAddressZip} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.informantAddressZip}</small>
                        </div>
                    </div>
            </form>
        </div>
    );
}

export default DeathCertForm;
