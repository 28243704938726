import React, {useEffect, useState} from 'react';
import Axios from "axios"
import contactSchema from "../Validations/contactSchema"
import * as yup from "yup"

const initialContactFormValues = {name:"",email:"",phone:"",message:""}
const initialContactFormErrors = {name:"",email:"",message:""}
const initialsubmitDisabled = true;


const ContactForm = () => {

      //////////////// STATES ////////////////
      const [submitDisabled, setsubmitDisabled] = useState(initialsubmitDisabled);
      const [contactFormValues, setContactFormValues] = useState(initialContactFormValues);
      const [contactFormErrors, setContactFormErrors] = useState(initialContactFormErrors)
      const [submitSuccess, setSubmitSuccess] = useState(false)
      const [submitError, setSubmitError] = useState(false)
     

     
      const onChange = evt => {
          const { name, value } = evt.target
          
          name !== 'phone' && yup.reach(contactSchema, name)
          .validate(value)
          .then(() => {
              setContactFormErrors({
                  ...contactFormErrors,
                  [name]:''
              });
          })
          .catch(err => {
              setContactFormErrors({
                  ...contactFormErrors,
                  [name]: err.errors[0]
              });
          });
  
          setContactFormValues({
              ...contactFormValues,
              [name]: value
          })
      }
  
  
      const addContact = (evt) => {
          evt.preventDefault()

          let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
          };

          //console.log('hello from add contact')
          Axios.post("https://preciousembers.com/backend/submit-contact", { //"http://preciousembers.com/submit""https://preciousembers.com/backend/submit"
              name: contactFormValues.name,
              email: contactFormValues.email,
              phone: contactFormValues.phone,
              message: contactFormValues.message
                }              , axiosConfig
          ).then((res) => {
              //console.log("success")
              //console.log(res)
              setSubmitSuccess(true)
          }).catch(err=> {
              //console.log('hi')
              //console.log(err)
              setSubmitError(true)
          
          }) 
      }
  
      useEffect(() => {
          contactSchema.isValid(contactFormValues).then((valid) => {
            setsubmitDisabled(!valid);
          });
        }, [contactFormValues]);
    return (
        <div>
            <hr/>
            <div className="container">
    <h3>Contact Us</h3>

        <small>We will never share your info with anyone else.</small>
<form className="form-block" action="/" method="post" onSubmit={addContact}>
      <div className="form-group">
        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" name="name" value={contactFormValues.name} onChange={onChange}/>
      </div> 
      <div className="form-group">  
         <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" name="email" value={contactFormValues.email} onChange={onChange}/>
       </div>
       <div className="form-group">   
        <input type="tel" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone" name="phone" value={contactFormValues.phone} onChange={onChange}/>
       </div>
       <div className="form-group">   
        <textarea className="form-control" id="inputMessage" aria-describedby="emailHelp" placeholder="Message" name="message" value={contactFormValues.message} onChange={onChange}></textarea>
      </div>
      <button type="submit" disabled={submitDisabled} className="btn btn-block btn-primary form-btn">Send</button>
</form>
<div className="errors" style={{ color: "red" }}>
          {contactFormErrors.name}
          <br />
          {contactFormErrors.email}
          <br />
          {contactFormErrors.message}
          <br />
        </div>
</div>
            {submitSuccess && <h5 style={{color:"green"}}>Message sent. We will be in touch shortly. Thank you.</h5>}
            {submitError && <h5 style={{color:"red"}}>Message not sent. Please try again or reach out to us by phone or email.</h5>}
           
        </div>
    );
}

export default ContactForm;
