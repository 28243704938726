import React, {useEffect} from 'react';
import ContactForm from './ContactForm';


const Contact = () => {

    useEffect(() => {
        window.scrollTo(0, 0); //have page appear at top of screen
      },[])

    return (
        <div>
            <ContactForm />

        <div className='container my-5'>
            <h3>Precious Embers</h3>
            <h4>10133 S. Prospect Avenue, Chicago, IL 60643, US</h4>
            <h5>Phone: <a href="tel:312-856-2456">(312) 856-2456</a> </h5>
            <h5>Email Us: <a href="mailto:info@preciousembers.com">info@preciousembers.com</a></h5>
        </div>
        </div>
    );
}

export default Contact;
