import React, { useState, useEffect } from 'react';

const Keepsakes = () => {
  const [keepsakes, setKeepsakes] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchKeepsakes();
  }, []);

  const fetchKeepsakes = async () => {
    try {
      const response = await fetch('//preciousembers.com/backend/keepsakes');
      const keepsakesData = await response.json();
      setKeepsakes(keepsakesData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching keepsakes:', error);
      setIsLoading(false);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="urns-container">
      <div className="urns-dropdown" onClick={toggleDropdown}>
        Keepsakes {dropdownOpen ? '▲' : '▼'}
      </div>
      {dropdownOpen && (
          <div className="urn-list">
          {isLoading ? (
            <div className="loader"></div>
          ) : (keepsakes.map((keepsake, index) => (
              <div key={keepsake.productID} className="keepsake">
                <input
                  type="image"
                  className="keepsake-card"
                  src={`//preciousembers.com/orders/images/Keepsakes/${keepsake.imageURL}`}
                  alt={keepsake.productName}
                  data-id={keepsake.productID}
                />
                <div className="keepsake-name">{keepsake.productName}</div>
                <div className="keepsake-price">${keepsake.price.toFixed(2)}</div>
              </div>
            ))
        )}
          </div>
      )}
    </div>
  );
};

export default Keepsakes;
