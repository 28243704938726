import React, { useState, useEffect } from 'react';

const Urns = () => {
  const [urns, setUrns] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchUrns();
  }, []);

  const fetchUrns = async () => {
    try {
      const response = await fetch('//preciousembers.com/backend/urns');
      const urnsData = await response.json();
      setUrns(urnsData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching urns:', error);
      setIsLoading(false);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="urns-container">
      <div className="urns-dropdown" onClick={toggleDropdown}>
        Urns {dropdownOpen ? '▲' : '▼'}
      </div>
      {dropdownOpen && (
        <div className="urn-list">
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            urns.map((urn, index) => (
              <div key={urn.productID} className="urn">
                <input
                  type="image"
                  className={`urn-card${index === 0 ? ' selected' : ''}`}
                  src={`//preciousembers.com/orders/images/${urn.imageURL}`}
                  alt={urn.productName}
                  data-name={urn.productName}
                  data-price={`$${urn.price}`}
                  data-desc={urn.description}
                  data-id={urn.productID}
                  data-free={urn.freeWithUpgrade}
                />
                <div className="urn-name">{urn.productName}</div>
                <div className="price">${urn.price.toFixed(2)}</div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Urns;
