import React, {useEffect} from 'react';
import product1Img from "../content/images/products/product1.jpg"
import product2Img from "../content/images/products/product2.jpg"
import product3Img from "../content/images/products/product3.jpg"
import Urns from './Urns';
import Keepsakes from './Keepsakes';



const Products = () => {
    useEffect(() => {
        window.scrollTo(0, 0); //have page appear at top of screen
      },[])
    return (
        <div>
            <br />
    <div className="container text-center">
       { <a className="btn btn-primary" rel="noreferrer" href="https://preciousembers.com/orders">Arrange Online Now</a>}
        <hr />
       
                <div className="row">
                    <div className="col-lg-3 mx-auto">
                        <img className="img-fluid" src={product1Img} alt="urns of various colors displayed on adjacent platforms of different heights"/>
                    </div>
                   <div className="col-lg-3 mx-auto">
                        <img className="img-fluid" src={product2Img} alt="black, marble-patterned urn with a wreath of roses around the base"/>
                    </div>
                    <div className="col-lg-3 mx-auto">
                        <img className="img-fluid" src={product3Img} alt="close-up shot of roses"/>
                    </div>
                </div>
                <div className="container my-5">
                    <h2 className="sans">VIEW OUR CATALOG</h2>
                    <div className="catalog">
                    <Urns />
                    <Keepsakes />
                    </div>
            </div> 
            </div>
        </div>
    );
}

export default Products;
