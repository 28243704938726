import React from 'react';

const Thankyou = () => {
    return (
        <div className="container thankyou">
           
            <p>Your decedent's information forms have been received. A copy was sent to the email provided.</p>
        
            <p>We will be in touch with you shortly. Please call us at (312) 856-2456 or email us at <a href="mailto:info@preciousembers.com">info@preciousembers.com</a> if you have any questions or concerns in the meantime. Thank you.</p>
        </div>
    );
}

export default Thankyou;
