import React, { useState } from 'react'

import * as yup from "yup"
import addDeathCertSchema from "../../Validations/addDeathCertSchema"


let stateList = [ "AK",
                      "AL",
                      "AR",
                      "AS",
                      "AZ",
                      "CA",
                      "CO",
                      "CT",
                      "DC",
                      "DE",
                      "FL",
                      "GA",
                      "GU",
                      "HI",
                      "IA",
                      "ID",
                      "IL",
                      "IN",
                      "KS",
                      "KY",
                      "LA",
                      "MA",
                      "MD",
                      "ME",
                      "MI",
                      "MN",
                      "MO",
                      "MS",
                      "MT",
                      "NC",
                      "ND",
                      "NE",
                      "NH",
                      "NJ",
                      "NM",
                      "NV",
                      "NY",
                      "OH",
                      "OK",
                      "OR",
                      "PA",
                      "PR",
                      "RI",
                      "SC",
                      "SD",
                      "TN",
                      "TX",
                      "UT",
                      "VA",
                      "VI",
                      "VT",
                      "WA",
                      "WI",
                      "WV",
                      "WY"]

const  initialDeathCertErrors = {
                                decName:"", maidenName:"",sex:"",dateOfBirth:"",dateOfDeath:"",timeOfDeath:"",placeOfDeathCity:"",placeOfDeathCounty:"", decHomeAddressLine1:"", decHomeAddressLine2:"", decHomeAddressCity:"", decHomeAddressState:"", decHomeAddressZip:"", usualOccupation:"", industry:"", education:"", fatherFirstName:"", fatherLastName:"", motherFirstName:"", motherMaidenName:"", physicianName:"", physicianAddress:"", physicianCity:"", physicianPhone:"", physicianFax: "", ssn:"", armedForces:"", maritalStatus:"", spouse:"", race:"", hisp:"", informantFirstName:"", informantLastName:"", informantRelationship:"", informantEmail:"", informantPhone:"", informantCell:"", informantAddressLine1:"", informantAddressLine2:"", informantAddressCity:"", informantAddressState:"", informantAddressZip:"",
                                }

const DeathCertForm = ({decedentObj, setDecedentObj}) => {

    const [deathCertErrors, setDeathCertErrors] = useState(initialDeathCertErrors);
    


    const handleChange = (evt) => {
        const {name, value} = evt.target

        yup.reach(addDeathCertSchema, name)
        .validate(value)
        .then(() => {
            setDeathCertErrors({
                ...deathCertErrors,
                [name]:''
            });
        })
        .catch(err => {
            setDeathCertErrors({
                ...deathCertErrors,
                [name]: err.errors[0]
            });
        });



        setDecedentObj({...decedentObj,
                        [name]: value})
        //console.log(decedentObj)                
    }


    //console.log(deathCertErrors)

    // useEffect(() => {
    //     let interval = setInterval(() => {
    //         if (decNameField.current) {
    //           setDecedentObj({...decedentObj,
    //                             decName: decNameField.current.value})
    //           clearInterval(interval)
    //                         }
    //           //do the same for all autofilled fields
              
    //       }, 100)
    //     });
    
    return (
        <div className='form-container'>
            <form >
                <div className="form-group">
                    <div className="form-row"><b>Deceased Home Address: </b></div>

                    <div className="row form-row">
                        <label >Street Address Line 1*: </label>
                        <input type="text" name="decHomeAddressLine1" value={decedentObj.decHomeAddressLine1} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.decHomeAddressLine1}</small>
                    </div>
                    <div className="row form-row">
                        <label >Street Address Line 2: </label>
                        <input type="text" name="decHomeAddressLine2" value={decedentObj.decHomeAddressLine2} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.decHomeAddressLine2}</small>
                    </div>

                    
                    <div className="row form-row">
                        <label >City*:  </label>
                        <input type="text" name="decHomeAddressCity" value={decedentObj.decHomeAddressCity} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.decHomeAddressCity}</small>
                    </div>
                    
                    

                    <div className="row form-row">
                        <label >State*:  </label>
                        <select name="decHomeAddressState" value={decedentObj.decHomeAddressState} onChange={handleChange}>
                        <option value="">-Select State-</option>
                        {stateList.map(state => {
                            return <option value={state}>{state}</option>;
                        })}
                         </select>
                        <small className="error-display">{deathCertErrors.decHomeAddressState}</small>
                       
                    </div>

                   

                    <div className="row form-row">
                        <label >Postal/Zip Code*: </label>
                        <input type="text" name="decHomeAddressZip" value={decedentObj.decHomeAddressZip} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.decHomeAddressZip}</small>
                    </div>

                </div>
            </form>
        </div>
    );
}

export default DeathCertForm;
