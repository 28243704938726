import React, {useState, useRef, useEffect} from 'react';
import {Redirect} from 'react-router-dom'
//import DeathNoticeForm from './DeathNoticeForm'
import MiscDeathCertForm from './deathcert-forms/MiscDeathCertForm'
import FamDeathCertForm from './deathcert-forms/FamDeathCertForm'
import BasicDeathCertForm from './deathcert-forms/BasicDeathCertForm'
import AddDeathCertForm from './deathcert-forms/AddDeathCertForm'
import CremationAuthForm from './CremationAuthForm'
import SignatureInput from './SignatureInput'
import axios from "axios"
import addDeathCertSchema from '../Validations/addDeathCertSchema';
import basicDeathCertSchema from '../Validations/basicDeathCertSchema';
import famDeathCertSchema from '../Validations/famDeathCertSchema';
import miscDeathCertSchema from '../Validations/miscDeathCertSchema';
import cremAuthSchema from '../Validations/cremAuthSchema';



const Forms = ({decedentObj, setDecedentObj}) => {
    //const [displayDeathNoticeForm, setDisplayDeathNoticeForm] = useState(false);
    const [displayBasicDeathCertForm, setDisplayBasicDeathCertForm] = useState(false);
    const [displayMiscDeathCertForm, setDisplayMiscDeathCertForm] = useState(false);
    const [displayFamDeathCertForm, setDisplayFamDeathCertForm] = useState(false);
    const [displayAddDeathCertForm, setDisplayAddDeathCertForm] = useState(false);
    const [displayCremationAuthForm, setDisplayCremationAuthForm] = useState(false);
    const [displaySignature, setDisplaySignature] = useState(false);
    const [savingEmail, setSavingEmail] = useState("");
    const [basicValid, setBasicValid] = useState(false);
    const [addressValid, setAddressValid] = useState(false);
    const [miscValid, setMiscValid] = useState(false);
    const [familyValid, setFamilyValid] = useState(false);
    const [cremAuthValid, setCremAuthValid] = useState(false);
    const [signatureValid, setSignatureValid] = useState(false);
    const [createDecedentStatus, setCreateDecedentStatus] = useState("");
    const [saveProgressStatus, setSaveProgressStatus] = useState("");
    const [loading, setLoading] = useState(false)
    // const [deathCertErrors, setDeathCertErrors] = useState(initialDeathCertErrors);




    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        }
/*
    const toggleDeathNotice = () => {
        setDisplayDeathNoticeForm(!displayDeathNoticeForm)
    }
*/

    const toggleBasicDeathCert = () => {
        setDisplayBasicDeathCertForm(!displayBasicDeathCertForm)
    }

    const toggleAddDeathCert = () => {
        setDisplayAddDeathCertForm(!displayAddDeathCertForm)
    }

    const toggleFamDeathCert = () => {
        setDisplayFamDeathCertForm(!displayFamDeathCertForm)
    }

    const toggleMiscDeathCert = () => {
        setDisplayMiscDeathCertForm(!displayMiscDeathCertForm)
    }

    const toggleCremationAuth = () => {
        setDisplayCremationAuthForm(!displayCremationAuthForm)
    }

    const toggleSignature = () => {
        setDisplaySignature(!displaySignature)
    }

    const handleSaveProgress = () => {
        const saveForLaterDecedent = {savedEmail: savingEmail, ...decedentObj}
        axios.post("https://preciousembers.com/backend/save-progress", saveForLaterDecedent, axiosConfig)
        .then(res => {
            //console.log(res)
            setSaveProgressStatus("success")
        })
        .catch(err => {
            //console.log(err)
            setSaveProgressStatus("failed")
        })
        //console.log(savingEmail)
    }
    

    const handleChange = (evt) => {
        setSavingEmail(evt.target.value)
    }
/*
    const confirmFamilyNotBlank = () => {
        if (decedentObj.familyMembers.length > 0) {
            return true
        } else if (decedentObj.familyMembers.length === 0) {
            return (window.confirm("Are you sure you want to leave family members blank?"))
        }
    }
*/
    const checkIfDuplicateThenCreate = () => {
        //console.log("checking")
        setLoading(true)
        // check if there's already a decedent with that name
        axios.post("https://preciousembers.com/backend/check", decedentObj, axiosConfig) // api
        .then(res => {
            //console.log(res.data)
            if (res.data.length === 0) {
                axios.post('https://preciousembers.com/backend/create-auth-pdf', decedentObj, axiosConfig).then(res => {
                            
                            axios.post('https://preciousembers.com/backend/create-release-pdf', decedentObj, axiosConfig).then(res =>   {
                                createNewDecedent()})
                                .catch(err => {
                                    console.log("create-release", err)
                                    setCreateDecedentStatus("failed")
                                })
                          
                        }
                        ).catch(err => {
                            console.log("create-auth", err)
                            setCreateDecedentStatus("failed")
                        })
               
            } else {
                    if(window.confirm("A decedent entry with that name already exists. Do you really want to create a new entry? If yes, click 'OK'. If not, please click 'Cancel' and contact us by email, info@preciousembers.com.")) {
                        axios.post('https://preciousembers.com/backend/create-auth-pdf', decedentObj, axiosConfig).then(res => {
                                    
                                    axios.post('https://preciousembers.com/backend/create-release-pdf', decedentObj, axiosConfig).then(res =>   {
                                        createNewDecedent()})
                                        .catch(err => {
                                            console.log("create-release", err)
                                            setCreateDecedentStatus("failed")
                                        })
                                  
                                }
                                ).catch(err => {
                                    console.log("create-auth", err)
                                    setCreateDecedentStatus("failed")
                                })
                       
                    }
                } 
            })
        .catch(err => {
            //console.log('check front end', err)
            setCreateDecedentStatus("failed")
            }
        )
    }
    const createNewDecedent= () => {
        // axios.post('http://preciousembers.com/api/create-auth-pdf', decedentObj)
        // .then(res => {
        //     //console.log(res)
        // }).catch(err => {
        //     //console.log(err)
        // }).then(
        axios.post("https://preciousembers.com/backend/create-decedent", decedentObj, axiosConfig)
        .then(res => {
            //console.log(res.data)
            setCreateDecedentStatus('success')
            setLoading(false)
        })
        .catch(err => {
            //console.log('create-decedent', err)
            setCreateDecedentStatus('failed')
            setLoading(false)
            })}
    //     )
    // }

    const handleCreate = () => {
        //if (confirmFamilyNotBlank()) { 
        checkIfDuplicateThenCreate()
        // createPdfs()
        //}     
    }


        //   .then(() => axios.get('http://preciousembers.com/api/fetch-auth-pdf', { responseType: 'blob' }))
        //   .then((res) => {
        //     const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
    
        //     saveAs(pdfBlob, 'newAuthPdf.pdf');
        //   })

    //       axios.post('http://preciousembers.com/api/create-release-pdf', decedentObj)
    //     //   .then(() => axios.get('http://preciousembers.com/api/fetch-release-pdf', { responseType: 'blob' }))
    //     //   .then((res) => {
    //     //     const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
    
    //     //     saveAs(pdfBlob, 'newReleasePdf.pdf');
    //     //   })
    //   }

      const emailField = useRef(null)

    //   const validityUpdate = (valid, cert) => {
    //       return {...formsValid, [cert]: valid}
    //   }
      

      useEffect(() => {
          addDeathCertSchema.isValid(decedentObj).then(valid => {
              setAddressValid(valid)
              //console.log("add deathcert valid: ", valid)
                }
          )
          basicDeathCertSchema.isValid(decedentObj).then(valid => {
              setBasicValid(valid)
              //console.log("basic deathcert valid: ", valid)
                }
          )
          famDeathCertSchema.isValid(decedentObj).then(valid => {
              setFamilyValid(valid)
              //console.log("fam deathcert valid: ", valid)
                }
          )
          miscDeathCertSchema.isValid(decedentObj).then(valid => {
            setMiscValid(valid)
            //console.log("misc deathcert valid: ", valid)
                }
          )


          cremAuthSchema.isValid(decedentObj).then(valid => 
            setCremAuthValid(valid))
        if (decedentObj.signature !== "" & decedentObj.signature !== "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=") {
                    setSignatureValid(true)
                } else {
                    setSignatureValid(false)
                }
            }
       
        , [decedentObj]);

    return (

      
            <div>

                <div className="container">
                                <div className="spacer">
                </div>
                    <h1 className="sans text-left">Decedent Forms</h1>
                    <p className="text-left">Please fill out form sections below and click "Submit" to share your decedent info. To save and finish later, fill out your email and click "Save Progress" </p>
                    <p className="text-left">* indicates a required field (choose N/A as needed)</p>
                    <div className="form-block">
                    <div className="formCard btn-primary w-100" >
                        <h5 className="sans">1) Decedent Basic Info - Form Status: {basicValid === true ? "✅ ": "❌"} </h5><br />
                
                        {!displayBasicDeathCertForm && <small className="display-toggle" onClick={toggleBasicDeathCert}>▼ (click to display form)</small>}
                
                        {displayBasicDeathCertForm && <small className="display-toggle" onClick={toggleBasicDeathCert}>▲ (click to hide form)</small>}
                        {displayBasicDeathCertForm && <BasicDeathCertForm decedentObj={decedentObj} setDecedentObj={setDecedentObj} />}
                    </div>
                    <div className="formCard btn-primary w-100" >
                        <h5 className="sans">2) Decedent Address Info - Form Status: {addressValid ? "✅ ": "❌"} </h5><br />
                
                        {!displayAddDeathCertForm && <small className="display-toggle" onClick={toggleAddDeathCert}>▼ (click to display form)</small>}
                
                        {displayAddDeathCertForm && <small className="display-toggle" onClick={toggleAddDeathCert}>▲ (click to hide form)</small>}
                        {displayAddDeathCertForm && <AddDeathCertForm decedentObj={decedentObj} setDecedentObj={setDecedentObj} />}
                    </div>
                    <div className="formCard btn-primary w-100" >
                        <h5 className="sans">3) Decedent Family/Next Of Kin Info - Form Status: {familyValid ? "✅ ": "❌"} </h5><br />
                
                        {!displayFamDeathCertForm && <small className="display-toggle" onClick={toggleFamDeathCert}>▼ (click to display form)</small>}
                
                        {displayFamDeathCertForm && <small className="display-toggle" onClick={toggleFamDeathCert}>▲ (click to hide form)</small>}
                        {displayFamDeathCertForm && <FamDeathCertForm decedentObj={decedentObj} setDecedentObj={setDecedentObj} />}
                    </div>
                    <div className="formCard btn-primary w-100" >
                        <h5 className="sans">4) Misc. Info for Death Certification - Form Status: {miscValid ? "✅ ": "❌"} </h5><br />
                
                        {!displayMiscDeathCertForm && <small className="display-toggle" onClick={toggleMiscDeathCert}>▼ (click to display form)</small>}
                
                        {displayMiscDeathCertForm && <small className="display-toggle" onClick={toggleMiscDeathCert}>▲ (click to hide form)</small>}
                        {displayMiscDeathCertForm && <MiscDeathCertForm decedentObj={decedentObj} setDecedentObj={setDecedentObj} />}
                    </div>
               
                    <div className="formCard btn-primary w-100" >
                        <h5 className="sans">5) Info for Cremation Authorization - Form Status: {cremAuthValid ? "✅ ": "❌"} </h5><br />
                
                        {!displayCremationAuthForm && <small className="display-toggle" onClick={toggleCremationAuth}>▼ (click to display form)</small>}
                
                        {displayCremationAuthForm && <small className="display-toggle" onClick={toggleCremationAuth}>▲ (click to hide form)</small>}
                        {displayCremationAuthForm && <CremationAuthForm decedentObj={decedentObj} setDecedentObj={setDecedentObj}/>}
                    </div>
                    {/*
                    <div className="formCard btn-primary w-100">
                        <h5 className="sans">6) Info for Death Notice - Form Status: {decedentObj.familyMembers.length > 0 ? "✅ ": "⚠️"}</h5><br />
                
                        {!displayDeathNoticeForm && <small className="display-toggle" onClick={toggleDeathNotice}>▼ (click to display form)</small>}
                
                        {displayDeathNoticeForm && <small className="display-toggle" onClick={toggleDeathNotice}>▲ (click to hide form)</small>}
                        {displayDeathNoticeForm && <DeathNoticeForm decedentObj={decedentObj} setDecedentObj={setDecedentObj}/>}
                    </div>
                */}
                    <div className="formCard btn-primary w-100">
                        <h5 className="sans">6) Signature - Status: {signatureValid ? "✅ ": "❌"}</h5><br />
                
                        {!displaySignature && <small className="display-toggle" onClick={toggleSignature}>▼ (click to display form)</small>}
                
                        {displaySignature && <small className="display-toggle" onClick={toggleSignature}>▲ (click to hide form)</small>}
                        {displaySignature && <SignatureInput decedentObj={decedentObj} setDecedentObj={setDecedentObj} />}
                    </div>
                    </div>
                </div>
                            <div className="form-group family">
                                <div className="col">
                                    <button className="col-3 btn-primary mx-auto" onClick={handleCreate} disabled={!( addressValid & basicValid & familyValid & miscValid & cremAuthValid & signatureValid)}>
                                        Submit
                                    </button><br />
                                    <div className="col error-display" style={{visibility: !( addressValid & basicValid & familyValid & miscValid & cremAuthValid & signatureValid) ? "visible": "hidden"}} >Fix errors to submit</div>
                                    <div className="col error-display" style={{visibility: createDecedentStatus === "failed" ? "visible": "hidden"}} >Something went wrong and your information was not sent. Please try again.</div>
                                    {loading && <div className="col"><div className="col">Sending data... <div className="loader"></div></div>
                                    <div className="col">Please be patient as this may take awhile.</div></div>}
                                </div>
                                
                                <div className="form-inline">
                                <div className="col"><label htmlFor="email">Want to save and come back later? Provide email and we'll send you a link</label></div>
                                    <div className="col">
                                        <input ref={emailField} type="text" name="email" value={savingEmail} onChange={handleChange}/>
                                        <button className='btn btn-primary' onClick={handleSaveProgress} style={{marginLeft:"1%"}}> Save Progress </button>
                                    </div>
                                </div>
                            </div>
                                {(saveProgressStatus === "failed") &&
                                <div className="error-display">
                                    Something went wrong, progress not saved properly. Please try again.
                                </div>}
                
                                {(saveProgressStatus === "success") &&
                                <div className="success-display">
                                    Progress saved. Instructions to return later can be found in email sent to address provided.
                                </div>}
                {createDecedentStatus === "success" && <Redirect to={{
                    pathname: '/Thankyou'
                }}/> }
            </div>
        
    );
}

export default Forms;
