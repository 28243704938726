import * as yup from "yup"

export default yup.object().shape({
    occupation: yup
    .string()
    .required("Occupation is required. If unemployed, enter 'N/A'"),
    industry: yup
    .string()
    .required("Industry is required. If unemployed, enter 'N/A'"),
    education: yup
    .string()
    .required("Education is required. If education level is not known, select 'Decedent's education level is not known'"),
    physicianName: yup
    .string()
    .required("Physician name is required. If no physician, enter 'N/A'."),
    physicianAddress: yup
    .string()
    .required("Physician's address is required. If no physician, enter 'N/A'."),
    physicianCity: yup
    .string()
    .required("Physician's city is required. If no physician, enter 'N/A'."),
    physicianAddressState: yup
    .string()
    .required("Physician's state is required. If no physician, enter 'N/A'."),
    physicianAddressZip: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .required("Physician's zip code is required. If no physician, enter 'N/A'."),

    physicianPhone: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits')
    .required("Physician's phone is required. If no physician, enter '0000000000' (Ten zeroes)."),
    physicianFax: yup
    .string()
    .nullable(true)
    .notRequired()
    .test(
        "physicianFaxValidation",
        "Must be exactly 10 digits",
        (value) => {
          if (!value) {
            return true;
          }
          return value.match(/^[0-9]{10}$/);
        }
      ),
    ssn: yup
    .string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(9, 'Must be exactly 9 digits')
    .max(9, 'Must be exactly 9 digits')
    .required(),
    armedForces: yup
    .string()
    .required(),
    maritalStatus: yup
    .string()
    .required("maritalStatus is required"),
    spouse: yup
    .string()
    .required("survivingSpouseName is required"),
    race: yup
    .string()
    .required("Race is required"),
    hisp: yup
    .string()
    .required("Hispanic Origin is required")
})