import * as yup from "yup"


export default yup.object().shape({

    decHomeAddressLine1: yup
    .string()
    .required(),
    decHomeAddressLine2: yup
    .string(),
    decHomeAddressCity: yup
    .string()
    .required(),
    decHomeAddressState: yup
    .string()
    .required(),
    decHomeAddressZip: yup
    .string()
   .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .required("Decedent Zip Code is required") ,

    
})