import React, {useEffect} from 'react';
import {NavLink, Link} from "react-router-dom"
import affordableImg from "../content/images/home/affordable.jpg"
import fullServiceImg from "../content/images/home/fullcremationserv.jpg"
import supportImg from "../content/images/home/supportattimes.jpg"
import ContactForm from './ContactForm';




const Home = () => {


    useEffect(() => {
        window.scrollTo(0, 0); //have page appear at top of screen
      },[])

    return (
        
            <div>
        <div className="jumbotron jumbotron-fluid flex-column justify-content-center">
          <div className="container text-center ">
              <br />
            <h1>PRECIOUS EMBERS CREMATIONS CHICAGOLAND</h1>
            <h3>Affordable Cremations</h3>
              <br />
            <a className="btn btn-primary" rel="noreferrer" href="https://preciousembers.com/orders">Arrange Online Now</a>
          </div>
        </div>
        <div className="container">
            <h2>Cremation Service at an Affordable Price</h2>
            <hr />
            {/* <a className="my-3 btn btn-primary" target="_blank" rel="noreferrer" href="https://preciousembers.partingpro.com/store">Order Now</a> */}
        </div>
        <br />
        <div className="container">
            <div className ="row "> 

                <div className="col-md-6 mx-auto">
                <div className='img-container'>
                    <img className="img-fluid  my-auto" src={fullServiceImg} alt=""/>
                </div>
                </div>

                <div className="col-md-6 mx-auto">
                    <h2>Full Cremation Services</h2>
                    <h5>All Services Included - $695</h5>
                    <p>
                    Precious Embers provides affordable cremation services in Chicago and its surrounding areas. We are a family-owned business and  are available 24 hours a day. All arrangements can be done online and by phone. We travel to you regardless of location. We ensure that the last journey with your loved one is filled with contentment and ease for you. We take pride in being of service to families in need of help during this difficult time. Learn more about our $695 cremation service.                    </p>
                    <Link className="btn btn-primary" to="/Services">Learn More</Link>
                </div>
            </div>
        </div>
        <br />
        <div className="container">
            <div className ="row flex-row-reverse"> 

                <div className="col-md-6 mx-auto">
                <div className='img-container'>
                    <img className="img-fluid  my-auto" src={supportImg}  alt="USA flag in the foreground of an autumn lake scene"/>
                </div>
                </div>

                <div className="col-md-6 mx-auto my-auto">
                    <h2>Support During Times Of Grief</h2>
                    <p>
                    Precious Embers provides a cremation service that eliminates worry and expense at a difficult time when a loved one has passed away. We take special care in carrying out this service and are empathetic to your loss. We are not just a cremation company providing a service but a family standing by you during your sorrowful time. We become part of your family and ensure that you get all of the support you will need. Call us for more information and details.
                    </p>
                </div>
            </div>
        </div>
        <br />
        <div className="container">
            <div className ="row "> 

                <div className="col-md-6 mx-auto">
                <div className='img-container'>
                    <img className="img-fluid  my-auto" src={affordableImg} alt="bell in the foreground of a sunrise lake scene"/>
                </div>
                </div>

                <div className="col-md-6 mx-auto my-auto">
                    <h2>Affordable Price</h2>
                    <p>
                        Precious Embers has one low price. We do not exploit but empathize with you by offering affordable prices for cremation services. <strong>There are no hidden fees to cause you inconvenience or concern.</strong>
                    </p>
                    <a className="btn btn-primary" rel="noreferrer" href="https://preciousembers.com/orders">Arrange Online Now</a>
                </div>
            </div>
        </div>
        <br />
        <ContactForm />

<br />
       
        <div className='container my-5 text-left'>
            <h3>Precious Embers</h3>
            <h4>10133 S. Prospect Avenue, Chicago, IL 60643, US</h4>
            <h5>Phone: <a href="tel:312-856-2456">(312) 856-2456</a> </h5>
            <h5>Email Us: <a href="mailto:info@preciousembers.com">info@preciousembers.com</a></h5>
        </div>
        </div>
    );
}

export default Home;


