import React, {useEffect} from 'react';
import comfortImg from "../content/images/about/Comfort.jpg"
import completeImg from "../content/images/about/completeasst.jpg"
import visionImg from "../content/images/about/vision.jpg"
import {NavLink} from "react-router-dom"

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0); //have page appear at top of screen
      },[])
    
    return (
        <div>
            <div className="container-fluid blackdiv ">

<h2 className="sans">ABOUT PRECIOUS EMBERS</h2>
<hr className="whitehr"/>
<br />
<div className="text-center ">
<p className ="grey">Precious Embers provides cremation services in Chicago and its surrounding areas. We ensure that the last journey with your loved one is filled with contentment for you. Be it the removal of the deceased person or providing cremation, we do it all! We are here to bring comfort to you and your family members.
</p>

<NavLink className="btn btn-primary" to="/Services">Learn More</NavLink>
    <br />
</div>
</div>

<div className="container-fluid">
<br />
<div className="abouts">
    <div className="about container">
        <div className="container">
        <img className="rounded-circle" src={comfortImg} alt="couple walking on beach at sunset"/>
        </div>
        <h3>Comfort To You</h3>
        <p>Precious Embers is here to bring comfort to you and your family members. During the time of grief, pain, and loss, we provide services to ensure that you have one thing less to worry about. Our alternative burial arrangements eliminate discomfort and expenses through tender, and by careful handling of final arrangements for the loved one or a deceased family.</p>
    </div>
    <div className="about container">
        <div className="container">
        <img className="rounded-circle" src={completeImg} alt="boardwalk at sundown"/>
        </div>
        <h3>Complete Assistance</h3>
        <p>At Precious Embers, we will arrange for a respectful journey and cremation of a decedent, while you mourn the loss of your loved one. What’s different about us is the extreme empathy we have for you and your family. Be assured of getting every help concerning cremation at an affordable price.</p>
    </div>
    <div className="about container">
        <div className="container">
        <img className="rounded-circle" src={visionImg} alt="calm lake scene with a lone fisherman"/>
        </div>
        <h3>Vision</h3>
        <p>Our vision is to reach out to families when they need compassion in a time of sorrow. Your family will not be alone in your time of need. We are with you.</p>
        <NavLink className="btn btn-primary" to="/Contact">Contact Us</NavLink>
    </div>
    
</div>
<br />
<br />
<br />
<div className="text-center">
    {/* <a className="btn btn-primary round"  target="_blank" rel="noreferrer" href="https://preciousembers.partingpro.com/store">Order<br />Now</a> */}
</div>
<br />
</div>
        </div>
    );
}

export default About;
