import * as yup from "yup"

export default yup.object().shape({
    
    fatherFirstName: yup
    .string()
    .required(),
    fatherLastName: yup
    .string()
    .required(),
    motherFirstName: yup
    .string()
    .required(),
    motherMaidenName: yup
    .string()
    .required(),
    informantFirstName: yup
    .string()
    .required("informantFirstName is required"),
    informantLastName: yup
    .string()
    .required("informantLastName is required"),
    informantRelationship: yup
    .string()
    .required("informantRelationship is required"),
    informantEmail: yup
    .string()
    .email()
    .required("informantEmail is required"),
    informantPhone: yup
    .string()
    
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits')
    .required("informantPhone is required"),
    informantCell: yup
    .string().notRequired()
    // .default(undefined)
    // .matches(/^[0-9]+$/, "Must be only digits")
    // .min(10, 'Must be exactly 10 digits')
    // .max(10, 'Must be exactly 10 digits')
    // .notRequired()
    ,
    informantAddressLine1: yup
    .string()
    .required("Address is required"),
    informantAddressLine2: yup
    .string(),

    informantAddressCity: yup
    .string()
    .required("informantAddressCity is required"),
    informantAddressState: yup
    .string()
    .required("informantAddressState is required"),
    informantAddressZip: yup
    .string()
    .required("Informant Zip Code is required").matches(/^[0-9]+$/, "Must be only digits")
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')   
})