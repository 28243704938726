import React, { useState, useRef } from 'react'

import * as yup from "yup"
import basicDeathCertSchema from "../../Validations/basicDeathCertSchema"
import NaCheckbox from '../NaCheckbox'

const countyList = [
    'N/A (State besides IL)',
    'Cook ',
'DuPage ',
'Lake ',
'Will ',
'Kane ',
'McHenry ',
'Winnebago ',
'Madison ',
'St. Clair ',
'Champaign ',
'Sangamon ',
'Peoria ',
'McLean ',
'Rock Island ',
'Tazewell ',
'Kendall ',
'Kankakee ',
'LaSalle ',
'Macon ',
'DeKalb ',
'Vermilion ',
'Williamson ',
'Adams ',
'Jackson ',
'Whiteside ',
'Boone ',
'Coles ',
'Ogle ',
'Grundy ',
'Knox ',
'Henry ',
'Macoupin ',
'Stephenson ',
'Franklin ',
'Woodford ',
'Jefferson ',
'Marion ',
'Clinton ',
'Livingston ',
'Fulton ',
'Lee ',
'Morgan ',
'Monroe ',
'Effingham ',
'Bureau ',
'Christian ',
'Randolph ',
'McDonough ',
'Logan ',
'Montgomery ',
'Iroquois ',
'Saline ',
'Jersey ',
'Shelby ',
'Jo Daviess ',
'Fayette ',
'Perry ',
'Douglas ',
'Crawford ',
'Hancock ',
'Edgar ',
'Warren ',
'Union ',
'Bond ',
'Wayne ',
'Piatt ',
'Lawrence ',
'De Witt ',
'Richland ',
'Clark ',
'Pike ',
'Mercer ',
'Moultrie ',
'Carroll ',
'Massac ',
'Washington ',
'White ',
'Mason ',
'Clay ',
'Ford ',
'Greene ',
'Johnson ',
'Cass ',
'Menard ',
'Marshall ',
'Wabash ',
'Cumberland ',
'Jasper ',
'Hamilton ',
'Schuyler ',
'Henderson ',
'Brown ',
'Edwards ',
'Alexander ',
'Putnam ',
'Pulaski ',
'Stark ',
'Gallatin ',
'Scott ',
'Calhoun ',
'Pope ',
'Hardin ',
]


const  initialDeathCertErrors = {
                                decName:"", maidenName:"",sex:"",dateOfBirth:"",dateOfDeath:"",timeOfDeath:"",placeOfDeathCity:"",placeOfDeathCounty:"", decHomeAddressLine1:"", decHomeAddressLine2:"", decHomeAddressCity:"", decHomeAddressState:"", decHomeAddressZip:"", usualOccupation:"", industry:"", education:"", fatherFirstName:"", fatherLastName:"", motherFirstName:"", motherMaidenName:"", physicianName:"", physicianAddress:"", physicianCity:"", physicianPhone:"", physicianFax: "", ssn:"", armedForces:"", maritalStatus:"", spouse:"", race:"", hisp:"", informantFirstName:"", informantLastName:"", informantRelationship:"", informantEmail:"", informantPhone:"", informantCell:"", informantAddressLine1:"", informantAddressLine2:"", informantAddressCity:"", informantAddressState:"", informantAddressZip:"",
                                }

const DeathCertForm = ({decedentObj, setDecedentObj}) => {

    const [deathCertErrors, setDeathCertErrors] = useState(initialDeathCertErrors);
    


    const handleChange = (evt) => {
        const {name, value} = evt.target

        yup.reach(basicDeathCertSchema, name)
        .validate(value)
        .then(() => {
            setDeathCertErrors({
                ...deathCertErrors,
                [name]:''
            });
        })
        .catch(err => {
            setDeathCertErrors({
                ...deathCertErrors,
                [name]: err.errors[0]
            });
        });



        setDecedentObj({...decedentObj,
                        [name]: value})
        //console.log(decedentObj)                
    }

    const decNameField = useRef(null)


    //console.log(deathCertErrors)

    // useEffect(() => {
    //     let interval = setInterval(() => {
    //         if (decNameField.current) {
    //           setDecedentObj({...decedentObj,
    //                             decName: decNameField.current.value})
    //           clearInterval(interval)
    //                         }
    //           //do the same for all autofilled fields
              
    //       }, 100)
    //     });
    
    return (
        <div className='form-container'>
            <form >
                <div className="form-group">

                    <div className="row form-row">
                        <label htmlFor="decName">Name of Deceased*: </label>
                        <input ref={decNameField} type="text" name="decName" value={decedentObj.decName} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.decName}</small>
                    </div>

                    <div className="row form-row">
                        <label htmlFor="maidenNameInput">Maiden Name (if applicable)*: </label>
                        <input type="text" name="maidenName" id="maidenNameInput"  value={decedentObj.maidenName} onChange={handleChange} disabled={decedentObj.maidenName === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="maidenName"/>
                        <small className="error-display">{deathCertErrors.maidenName}</small>
                    </div>

                    <div className="row form-row">
                        <label htmlFor="sex">Sex*: </label>
                        <select name="sex" id="sex" value={decedentObj.sex} onChange={handleChange}>
                            <option value="">-Select Sex-</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non-binary">Non-binary</option>
                        </select>
                        <small className="error-display">{deathCertErrors.sex}</small>
                    </div>
                    
                    <div className="row form-row">
                        <label htmlFor="dateOfBirth">Date of Birth*: </label>
                        <input type="date" name="dateOfBirth" value={decedentObj.dateOfBirth} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.dateOfBirth}</small>
                    </div>

                    <div className="row form-row">
                        <label htmlFor="placeOfBirth">Place of Birth*: </label>
                        <input type="test" name="placeOfBirth" value={decedentObj.placeOfBirth} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.placeOfBirth}</small>
                    </div>

                    <div className="row form-row">
                        <label htmlFor="dateOfDeath">Date of Death*: </label>
                        <input type="date" name="dateOfDeath" value={decedentObj.dateOfDeath} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.dateOfDeath}</small>
                    </div>

                    <div className="row form-row">
                        <label htmlFor="timeOfDeath">Time of Death*: </label>
                        <input type="time" name="timeOfDeath" value={decedentObj.timeOfDeath} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.timeOfDeath}</small>
                    </div>

                    <div className="row form-row">
                        <label htmlFor="placeOfDeathCity">Place of Death (city)*: </label>
                        <input type="text" name="placeOfDeathCity" value={decedentObj.placeOfDeathCity} onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.placeOfDeathCity}</small>
                    </div>

                    <div className="row form-row">
                        <label for="placeOfDeathCounty">Place of Death (county*)*</label>
                        <select name="placeOfDeathCounty" value={decedentObj.placeOfDeathCounty} onChange={handleChange}>
                        <option value="">-Select County-</option>
                        {countyList.map(county => {
                            return <option value={county}>{county}</option>;
                        })}
                        {/* could enable ability to input county from different state */}
                        {/* {decedentObj.placeOfDeathCounty === countyList[0] && <input placeholder="County Name (State)" type="text" value={countyException} onChange={handleNAchange}/>} */}
                </select>
                        <small className="error-display">{deathCertErrors.placeOfDeathCounty}</small>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default DeathCertForm;
