import * as yup from "yup"

export default yup.object().shape({
finalDispositionType: yup
.string()
.required("finalDispositionType is required"),
finalDispositionDetails: yup
.string()
.required("finalDispositionDetails is required"),
serviceDate: yup
.date()
.nullable(true)
.test(
  "required",
  "Service date is required",
  (value, context) => context.parent.serviceDateNA || value !== null
),
serviceDateNA: yup.boolean(),
valuables: yup
.string()
.required("Valuables is a required field. If no valuables, enter 'N/A'."),
diseases: yup
.string()
.required("Diseases is a required field. If no diseases, enter 'N/A'."),
implants: yup
.string()
.required("Implants is a required field. If no implants, enter 'N/A'")
})