import React, {useState, useEffect} from 'react';
import {Redirect, useParams} from 'react-router-dom'
import axios from 'axios'

// 

const Continue = ({ decedentObj, setDecedentObj}) => {
    const [localVerifyCode, setLocalVerifyCode] = useState("");
    const [storedVerifyCode, setStoredVerifyCode] = useState("")
    const [serverDecedentObj, setServerDecedentObj] = useState({});
    const [loading, setLoading] = useState(false);

    
    const localUUIDobj = {localUUID: useParams().uuid}

    const handleChange = (evt) => {
        const {value} = evt.target
        setLocalVerifyCode(value)                    
    }

    let axiosConfig = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
        }

     //console.log("decedentObj", serverDecedentObj)

    const handleVerify = () => {
        setLoading(true)
            // get uuid from params
            
                //post  request that sends the localUUID and gets the storedVerifyCode back
        axios.post("https://preciousembers.com/backend/verify", localUUIDobj, axiosConfig)
        .then(res => {
            setServerDecedentObj({...res.data[1], })
            setStoredVerifyCode(res.data[0])
            setLoading(false)
        })
        .catch(err => {
        })       
                //logic that checks whether the storedVerifyCode matches the localVerifyCode
        
                    //if storedVerifyCode === localVerifyCode, route to services and pass in the decedentObj
                    // if not equal, display message unsuccessful. "Try again or click here to start new entry (link to services)"
            
    }



    useEffect(() => { 
        //console.log('hi')
        if (storedVerifyCode === localVerifyCode && serverDecedentObj) {
            
            //console.log(serverDecedentObj)
            setDecedentObj(serverDecedentObj)
        }
        
    }, [storedVerifyCode, localVerifyCode, decedentObj, serverDecedentObj, setDecedentObj]);
    

    return (
        <div>
            <div className="container">
                <div className="col">
                    <p>Please enter 6-digit verification code sent in email.</p>
                    <input type="text" value = {localVerifyCode} onChange={handleChange}/> <br /><br />
                    {loading ? <div className="col">Loading info... <div className="loader"></div></div>
                    : <button className='btn btn-primary' onClick={handleVerify} > Verify Identity and Finish Entry </button>}                    
                </div>
                <br />

            </div>
            {/* line below checks that a verify code has been retrieved from the back end */}
            {storedVerifyCode ? (
  storedVerifyCode === localVerifyCode ? (
    <Redirect
      to={{
        pathname: "/forms",
      }}
    />
  ) : (
    <div className="col">
      The verification code entered was not correct. Please try again.
    </div>
  )
) : null}
        </div>
    );
}

export default Continue;

