import React, {useEffect, useState} from 'react';
import {Switch, Route, useRouteMatch} from 'react-router-dom'
import Continue from './Continue'
import Forms from './Forms';

const initialDecedentObj = {decedentId:'', decName:"", maidenName:"", sex:"", dateOfBirth:"", placeOfBirth:"", dateOfDeath:"", timeOfDeath:"", placeOfDeathCity:"", placeOfDeathCounty:"", decHomeAddressLine1:"", decHomeAddressLine2:"", decHomeAddressCity:"", decHomeAddressState:"", decHomeAddressZip:"", occupation:"", industry:"", education:"", fatherFirstName:"", fatherLastName:"", motherFirstName:"", motherMaidenName:"", physicianName:"", physicianAddress:"", physicianCity:"",physicianAddressState:"", physicianAddressZip:"", physicianPhone:"", physicianFax:"", ssn:"", armedForces:"", maritalStatus:"", spouse:"", race:"", hisp:"", informantFirstName:"", informantLastName:"", informantRelationship:"", informantEmail:"", informantPhone:"", informantCell:"", informantAddressLine1:"", informantAddressLine2:"", informantAddressCity:"", informantAddressState:"", informantAddressZip:"", finalDispositionType:"", finalDispositionDetails:"", serviceDate:"", valuables:"", diseases:"", implants:"", signature:"", familyMembers:[], additionalInfo:"" }

const FormsContinueParent = () => {
    
    let match = useRouteMatch()
    
    const [decedentObj, setDecedentObj] = useState(initialDecedentObj)
    
    useEffect(() => {
        window.scrollTo(0, 0); //have page appear at top of screen
    }, []);

 

      
    return (
        <div>
                    <Switch>
                        <Route path={`${match.path}/continue/:uuid`}>
                            <Continue decedentObj={decedentObj} setDecedentObj={setDecedentObj}/>
                        </Route>
                        <Route exact path="/forms">
                            <Forms decedentObj={decedentObj} setDecedentObj={setDecedentObj} /> 
                        </Route>
                     </Switch>
            
        </div>
    );
}

export default FormsContinueParent;
