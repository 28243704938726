import React from 'react';
import affordableImg from "../content/images/services/affordableprices.jpg"
import convenience from "../content/images/services/convenience.jpg"
import {NavLink} from "react-router-dom"


const Services = () => {

    return (

                    <div className="container">
                        <div className="spacer"></div>
                        <div className="col">
                            <h2 className="sans">AFFORDABLE CREMATION SERVICES</h2>
                            <hr/>
                            <br />
                            <div className="text-center ">
                                <p>
                                    Losing your loved ones is painful and sorrowful. The memories we share with them come striking at us, throwing us in a state of oblivion. Through those tough times, help from professional agencies such as Precious Embers comes in handy. Let us help you make the cremation process stress-free and convenient.
                                </p>
                                <p>
                                    Our cremation services are available for communities of Chicago and surrounding areas.
                                </p>
                                <NavLink className="btn btn-primary" to="/Contact">Contact Us</NavLink>
                                <br />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <br />
                                <img src={affordableImg} alt="United States flag in the foreground with an autumn lake scene in the background" />
                            </div>
                            <div className="col text-left">
                        <br />
                        <h3 className="sans text-center">Affordable Prices</h3>
                        <p>At Precious Embers our burial arrangement price is affordable at $695. Our services include careful and respectful handling of your loved one. We ensure that all duties are carried out until the final journey.</p>
                        <ul>
                            <li>
                                Removal of the deceased
                            </li>
                            <li>
                                Transportation to the crematory
                            </li>
                            <li>
                                Filing necessary authorizations and permits
                            </li>
                            <li>
                                Completing and filing death certificates
                            </li>
                            <li>
                                Direct cremation
                            </li>
                            <li>
                                Temporary container for cremated remains and delivery of cremains to family as part of the services provided in the cost
                            </li>
                        </ul>
                        <div className="text-center">
                                {/* <a className="btn btn-primary" target="_blank" rel="noreferrer" href="https://preciousembers.partingpro.com/store" >Order Now</a> */}
                        </div>
                                        </div>
                              </div>
                        <br />
                        <div className="row">
                                <div className="col">
                                    <img src={convenience} alt="Bouquet of pink roses shown aerially next to a bible." />
                                </div>
                                <div className="col">
                                    <h3 className="sans">Convenience Through Difficult Times</h3>
                                    <p>Precious Embers is committed to ensuring convenience to you during tough times. We provide two certified death certificates as per the affordable burial arrangement price. We are here for you, and we will make the process worry-free!</p>
                                    <p>We also offer Pre-need arrangements. Contact us for more information at no obligation.</p>
                                    <div className="text-center">
                                        <a className="btn btn-primary" rel="noreferrer" href="https://preciousembers.com/orders">Arrange Online Now</a>
                                    </div>

                                </div>
                                        
                        
                                </div>
                        <br />
                        <div className="container">
                            Looking to get started? Click Below For Our Decedent Forms Page<br />
                            <br />
                             <a className="btn btn-primary" target="" rel="noreferrer" href="/Forms">Decedent Forms</a>
                        </div>
                        <br />
                    </div>
    );
}

export default Services;
