import React, {useState} from 'react';
import cremAuthSchema from "../Validations/cremAuthSchema"
import * as yup from "yup"
import NaCheckbox from './NaCheckbox';



const initialCremAuthFormErrors = {
    finalDispositionType: ""
    //
}

const CremationAuthForm = ({decedentObj, setDecedentObj}) => {
    const [cremAuthErrors, setCremAuthErrors] = useState(initialCremAuthFormErrors);

    const handleChange = (evt) => {
        const {name, value} = evt.target

        yup.reach(cremAuthSchema, name)
        .validate(value)
        .then(() => {
            setCremAuthErrors({
                ...cremAuthErrors,
                [name]:''
            });
        })
        .catch(err => {
            setCremAuthErrors({
                ...cremAuthErrors,
                [name]: err.errors[0]
            });
        });
        

        setDecedentObj({...decedentObj,
                        [name]: value})
        
        
    }

    return (
        <div className='form-container container'>
            <div className="form-group">
                <div className="row form-row">
                    <label for="finalDispositionType">Final Disposition Type:</label>
                    <select name="finalDispositionType" id="disp_status" value={decedentObj.finalDispositionType} onChange={handleChange}>
                        <option value="">-Select Final Disposition Type-</option>
                        <option value="Release to">Release to</option>
                        <option value="Ship to">Ship to</option>
                        <option value="Other">Other (please provide details below)</option>
                    </select>
                    <small className="error-display">{cremAuthErrors.finalDispositionType}</small><br/>
                </div>
                <div className="row form-row area">
                        <div className="col area" >
                            <label htmlFor="finalDispositionDetails">Specific Details of Final Disposition: </label>
                            <textarea type="text" placeholder="Name of recipient, Address of recipient, or any relevant details" value={decedentObj.finalDispositionDetails} name="finalDispositionDetails" onChange={handleChange} disabled={decedentObj.finalDispositionDetails === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="finalDispositionDetails"/>
                        </div>
                        <small className="error-display">{cremAuthErrors.finalDispositionDetails}</small><br/>
                </div>
                <div className="row form-row">
                        <label htmlFor="serviceDate">Have you arranged for a viewing or service? If so, please provide the date: </label>
                        <input
  type="date"
  name="serviceDate"
  value={decedentObj.serviceDate === null ? "" : decedentObj.serviceDate}
  onChange={handleChange}
  disabled={decedentObj.serviceDate === null}
/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="serviceDate"/>
                    <small className="error-display">{cremAuthErrors.serviceDate}</small><br/>
                </div>
                <div className="row form-row area">
                    <div className="col area">
                        <label htmlFor="valuables">Are there any items of value to be placed with the deceased? If so, please list and provide instruction for disposal (otherwise, enter 'N/A'): </label>
                        <textarea type="text" name="valuables" value={decedentObj.valuables} onChange={handleChange} disabled={decedentObj.valuables === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="valuables"/>
                    </div>
                    <small className="error-display">{cremAuthErrors.valuables}</small><br/>
                </div>
                <div className="row form-row">
                    <div className="col area">
                        <label htmlFor="diseases">Did the decedent's death occur as a result of disease declared by the Illinois
                        Department of Public Health to be infectious, contagious, communicable or dangerous to public health? If so, please list the types of disease (otherwise, enter 'N/A').</label>
                        <textarea type="text" name="diseases" value={decedentObj.diseases} onChange={handleChange} disabled={decedentObj.diseases === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="diseases"/>
                    </div>
                    <small className="error-display">{cremAuthErrors.diseases}</small><br/>
                </div>
                <div className="row form-row">
                    <div className="col area">
                        <label htmlFor="implants">Did the decedent have a pacemaker or other material or implant that needs to be removed and disposed of by the funeral home prior to cremation? If so, please list and describe below (otherwise, enter 'N/A'). </label>
                        <textarea type="text" name="implants" value={decedentObj.implants} onChange={handleChange} disabled={decedentObj.implants === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="implants"/>
                    </div>
                    <small className="error-display">{cremAuthErrors.implants}</small><br/>
                
                </div>
            </div>
        </div>
    );
}

export default CremationAuthForm;
