import React, {useState} from 'react';
import SignaturePad from 'react-signature-canvas'


const SignatureInput = ({decedentObj, setDecedentObj}) => {

    let sigPad = {}   
    
    const clear = () => {
        sigPad.clear()
        setDecedentObj({...decedentObj,
                      signature: ""})
        //console.log(sigPad)
    }
    const trim = () => {
        setDecedentObj({...decedentObj,
                     signature: sigPad.getTrimmedCanvas().toDataURL('image/png')}
                     )
        //console.log(decedentObj)
    }

    return <div className="form-container">
    <div className="col sigGroup">
      <div className="sigContainer">
        <p>Sign the above documents by clicking and dragging below:</p>
        <SignaturePad canvasProps={{className: sigPad}}
          ref={(ref) => {sigPad = ref}} />
      </div>
      <div>
        <button className="buttons" onClick={clear}>
          Clear
        </button>
        <button className="buttons" onClick={trim}>
          Accept Signature
        </button>
      </div>
    </div>
    {(decedentObj.signature !== "" & decedentObj.signature !== "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=") //blank signature encoding <= ... for some reason, I wasn't able to include this as a variable...
      ? <div className="sigContainer sigDisplay">
        <p>Below is how your signature will appear on PDF documents: </p>
        <div className="sigImgWrapper">
          <img className="sigImage"
            src={decedentObj.signature} alt="the signature" />
        </div>
      </div>
      : <div className="col">
        <div className="error-display">Signature Required</div>
      </div>}
      <br />
  </div>
}

export default SignatureInput;