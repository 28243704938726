import React from 'react';

const NaCheckbox = ({decedentObj, setDecedentObj, inputName}) => {

    const handleNAChange = (evt) => {
        // two states
        // 1) when box is unchecked
        if (evt.target.checked) {
            if (evt.target.name === "serviceDate") {
                setDecedentObj({
                  ...decedentObj,
                  serviceDate: evt.target.checked ? null : "",
                  serviceDateNA: evt.target.checked,
                });
            } else if (evt.target.name === "physicianPhone") {
                setDecedentObj({...decedentObj,
                                [evt.target.name]:"0000000000"})
            } else if (evt.target.name === "physicianAddressZip") {
                setDecedentObj({...decedentObj,
                                [evt.target.name]:"00000"})
            }
            
            else {
            //console.log('state 1 fired', evt.target.name, decedentObj)
             setDecedentObj({...decedentObj,
                [evt.target.name]: "N/A"})
             }
                
        
        } else
        {// 2) when box is checked
        setDecedentObj({...decedentObj,
            [evt.target.name]: ""})}

        //console.log(decedentObj)

        


    } 

    return (
        <div>
            <div className="nacheckbox">
                <label htmlFor={`${inputName}Check`} style={{margin:"auto", marginLeft:"10%"}}>N/A</label>
                <input type="checkbox" name={inputName} id={`${inputName}Check`} style={{margin:"auto", marginLeft:"10%"}} onChange={handleNAChange}/>
            </div>
        </div>
    );
}

export default NaCheckbox;
