import React, { useState } from 'react'
import NaCheckbox from '../NaCheckbox'

import * as yup from "yup"
import miscDeathCertSchema from "../../Validations/miscDeathCertSchema"

const  initialDeathCertErrors = {
                                decName:"", maidenName:"",sex:"",dateOfBirth:"",dateOfDeath:"",timeOfDeath:"",placeOfDeathCity:"",placeOfDeathCounty:"", decHomeAddressLine1:"", decHomeAddressLine2:"", decHomeAddressCity:"", decHomeAddressState:"", decHomeAddressZip:"", usualOccupation:"", industry:"", education:"", fatherFirstName:"", fatherLastName:"", motherFirstName:"", motherMaidenName:"", physicianName:"", physicianAddress:"", physicianCity:"", physicianPhone:"", physicianFax: "", ssn:"", armedForces:"", maritalStatus:"", spouse:"", race:"", hisp:"", informantFirstName:"", informantLastName:"", informantRelationship:"", informantEmail:"", informantPhone:"", informantCell:"", informantAddressLine1:"", informantAddressLine2:"", informantAddressCity:"", informantAddressState:"", informantAddressZip:"",
                                }

const DeathCertForm = ({decedentObj, setDecedentObj}) => {

    let stateList = [ "AK",
                      "AL",
                      "AR",
                      "AS",
                      "AZ",
                      "CA",
                      "CO",
                      "CT",
                      "DC",
                      "DE",
                      "FL",
                      "GA",
                      "GU",
                      "HI",
                      "IA",
                      "ID",
                      "IL",
                      "IN",
                      "KS",
                      "KY",
                      "LA",
                      "MA",
                      "MD",
                      "ME",
                      "MI",
                      "MN",
                      "MO",
                      "MS",
                      "MT",
                      "NC",
                      "ND",
                      "NE",
                      "NH",
                      "NJ",
                      "NM",
                      "NV",
                      "NY",
                      "OH",
                      "OK",
                      "OR",
                      "PA",
                      "PR",
                      "RI",
                      "SC",
                      "SD",
                      "TN",
                      "TX",
                      "UT",
                      "VA",
                      "VI",
                      "VT",
                      "WA",
                      "WI",
                      "WV",
                      "WY"]

    const [deathCertErrors, setDeathCertErrors] = useState(initialDeathCertErrors);
    


    const handleChange = (evt) => {
        const {name, value} = evt.target

        yup.reach(miscDeathCertSchema, name)
        .validate(value)
        .then(() => {
            setDeathCertErrors({
                ...deathCertErrors,
                [name]:''
            });
        })
        .catch(err => {
            setDeathCertErrors({
                ...deathCertErrors,
                [name]: err.errors[0]
            });
        });



        setDecedentObj({...decedentObj,
                        [name]: value})
        //console.log(decedentObj)                
    }

    //console.log(deathCertErrors)

    // useEffect(() => {
    //     let interval = setInterval(() => {
    //         if (decNameField.current) {
    //           setDecedentObj({...decedentObj,
    //                             decName: decNameField.current.value})
    //           clearInterval(interval)
    //                         }
    //           //do the same for all autofilled fields
              
    //       }, 100)
    //     });
    
    return (
        <div className='form-container'>
            <form >


                <div className="form-group">
                    <div className="row form-row">
                        <label htmlFor="occupation">Decedent's Usual Occupation*: </label>
                        <input type="text" name="occupation" value={decedentObj.occupation} onChange={handleChange} disabled={decedentObj.occupation === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="occupation"/>
                        <small className="error-display">{deathCertErrors.occupation}</small>
                    </div>
                     <div className="row form-row">
                        <label htmlFor="occupation">Kind of Business or Industry*: </label>
                        <input type="text" name="industry" value={decedentObj.industry} onChange={handleChange} disabled={decedentObj.industry === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="industry"/>
                        <small className="error-display">{deathCertErrors.industry}</small>
                    </div>
                    <div className="row form-row">
                        <label htmlFor="education">Decedent's Education Level*: </label>
                        <select name="education" value={decedentObj.education} onChange={handleChange}>
                        <option value="" selected="selected">-- select one --</option>
    <option value="Unknown">Decedent's education level is not known</option>
    <option value="No formal education">No formal education</option>
    <option value="Primary education">Primary education</option>
    <option value="Secondary education">Secondary education or high school</option>
    <option value="GED">GED</option>
    <option value="Vocational qualification">Vocational qualification</option>
    <option value="Bachelor's degree">Bachelor's degree</option>
    <option value="Master's degree">Master's degree</option>
    <option value="Doctorate or higher">Doctorate or higher</option>
                        </select>
                        <small className="error-display">{deathCertErrors.education}</small>
                    </div>
                </div>
                <hr />
                 <div className="form-group">
                     <div className="row form-row">
                        <label htmlFor="physicianName">Physician's Name*: </label>
                        <input type="text" name="physicianName" value={decedentObj.physicianName} onChange={handleChange} disabled={decedentObj.physicianName === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="physicianName"/>
                        <small className="error-display">{deathCertErrors.physicianName}</small>
                                     </div>
                     <div className="row form-row">
                        <label htmlFor="physicianAddress">Physician's Address*: </label>
                        <input type="text" name="physicianAddress" value={decedentObj.physicianAddress} onChange={handleChange} disabled={decedentObj.physicianAddress === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="physicianAddress"/>
                        <small className="error-display">{deathCertErrors.physicianAddress}</small>
                                     </div>
                     <div className="row form-row">
                        <label htmlFor="physicianCity">Physician's City*: </label>
                        <input type="text" name="physicianCity" value={decedentObj.physicianCity} onChange={handleChange} disabled={decedentObj.physicianCity === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="physicianCity"/>
                        <small className="error-display">{deathCertErrors.physicianCity}</small>
                                     </div>
                    <div className="row form-row">
                        <label >Physician's State*:  </label>
                        <select name="physicianAddressState" value={decedentObj.physicianAddressState} onChange={handleChange} disabled={decedentObj.physicianAddressState === "N/A"}>
                        <option value="">-Select State-</option>
                        {stateList.map(state => {
                            return <option value={state}>{state}</option>;
                        })}
                         </select>
                         
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="physicianAddressState"/>
                        <small className="error-display">{deathCertErrors.physicianAddressState}</small>
                       
                    </div>

                   

                    <div className="row form-row">
                        <label >Physician's Postal/Zip Code*: </label>
                        <input type="text" name="physicianAddressZip" value={decedentObj.physicianAddressZip} onChange={handleChange}disabled={decedentObj.physicianAddressZip === "00000"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="physicianAddressZip"/>
                            <small className="error-display">{deathCertErrors.physicianAddressZip}</small>
                    </div>
                     <div className="row form-row">
                        <label htmlFor="physicianPhone">Physician's Phone*: </label>
                        <input type="text" name="physicianPhone" value={decedentObj.physicianPhone} onChange={handleChange} disabled={decedentObj.physicianPhone === "0000000000"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="physicianPhone"/>
                        <small className="error-display">{deathCertErrors.physicianPhone}</small>
                                     </div>
                                     <div className="row form-row">
                        <label htmlFor="physicianFax">Physician's Fax: </label>
                        <input type="text" name="physicianFax" value={decedentObj.physicianFax} onChange={handleChange} disabled={decedentObj.physicianFax === "0000000000"}/>
                        <small className="error-display">{deathCertErrors.physicianFax}</small>
                                     </div>
                 </div>
                <hr/>
                 <div className="form-group">
                     <div className="row form-row">
                        <label htmlFor="ssn">Social Security Number*: </label>
                        <input type="password" name="ssn" value={decedentObj.ssn} onChange={handleChange}/>
                        {decedentObj.ssn && decedentObj.ssn.length === 9 && 
                        <small style={{marginLeft: "5%"}}>Last 4: {`${decedentObj.ssn.split("").slice(5)[0]}${decedentObj.ssn.split("").slice(5)[1]}${decedentObj.ssn.split("").slice(5)[2]}${decedentObj.ssn.split("").slice(5)[3]}`}</small>}
                        <small className="error-display">{deathCertErrors.ssn}</small>

                    </div>
                     <div className="row form-row">
                        <label htmlFor="armedForces">Did the deceased ever serve in the armed forces?*: </label>
                        <label htmlFor="yes">Yes    </label>
                        <input type="radio" name="armedForces" id="armedYes" value="Yes" onChange={handleChange}/> 
                        <label htmlFor="no">No    </label>
                        <input type="radio" name="armedForces" id="armedNo" value="No" onChange={handleChange}/>
                        <small className="error-display">{deathCertErrors.armedForces}</small>
                                     </div>
                     <div className="row form-row">
                                     <label for="maritalStatus">Marital Status*:</label>
                                     <select name="maritalStatus" id="marital_status" value={decedentObj.maritalStatus} onChange={handleChange}>
                        <option value="">-Select Marital Status-</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Widowed">Widowed</option>
                        <option value="Separated">Separated</option>
                        <option value="Divorced">Divorced</option>
                                     </select>
                        <small className="error-display">{deathCertErrors.maritalStatus}</small>
                                     </div>
                     <div className="row form-row">
                        <label htmlFor="spouse">Surviving Spouse's Name* (use maiden name if applicable): </label>
                        <input type="text" name="spouse" value={decedentObj.spouse} onChange={handleChange} disabled={decedentObj.spouse === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="spouse"/>
                        <small className="error-display">{deathCertErrors.spouse}</small>
                                     </div>
                     {/* <div className="row form-row">
                        <label htmlFor="race">Race: </label>
                        <input type="text" name="race" value={decedentObj.race} onChange={handleChange} disabled={decedentObj.xxxxxx === "N/A"}/>
                            <NaCheckbox decedentObj={decedentObj} setDecedentObj={setDecedentObj} inputName="xxxxxx"/>
                                     </div> */}
                     <div className="row form-row">
                        <label htmlFor="race">Race*: </label>
                        <select name="race" id="race" value={decedentObj.race} onChange={handleChange}>
                            <option value="">-Select race-</option>
                            <option value="White">White</option>
                            <option value="Black or African American">Black or African American</option>
                            <option value="American Indian or Alaska Native">American Indian or Alaska Native</option>
                            <option value="Asian">Asian</option>
                            <option value="Native Hawaiian or Other Pacific Islander">Native Hawaiian or Other Pacific Islander</option>
                        </select>
                        <small className="error-display">{deathCertErrors.race}</small>
                    </div>
                     <div className="row form-row">
                        <label htmlFor="hisp">Hispanic Origin*: </label>
                        <select name="hisp" id="hisp" value={decedentObj.hisp} onChange={handleChange}>
                            <option value="">-Select Hispanic origin-</option>
                            <option value="None">No, not of Hispanic origin</option>
                            <option value="Yes, Mexican/Mexican-American/Chicano">Mexican/Mexican-American/Chicano</option>
                            <option value="Yes, Puerto Rican">Puerto Rican</option>
                            <option value="Yes, Cuban">Cuban</option>
                            <option value="Other">Other Hispanic</option>
                        </select>
                        <small className="error-display">{deathCertErrors.hisp}</small>
                    </div>
                 </div>
               
</form>
        </div>
    );
}

export default DeathCertForm;
